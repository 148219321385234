

















































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormFile
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { ExportPlanningAdherentModele, UpdateExportPlanningAdherentModeleModel } from "@/api/models/creneaux/exportPlanningAdherentModele";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormFile
  },
  name: "update-exportPlanningAdherentModele",
})
export default class EditExportPlanningAdherentModal extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateExportPlanningAdherentModeleSidebarActive!: boolean;
  @Prop({ required: true }) exportPlanningAdherentModeleId!: string;

  loading = false;
  required = required;
  loadingExportPlanningAdherentModele = false;

  imagePrincipaleFichier: any = null
  imageSecondaireFichier: any = null

  srcImagePrincipale: any = null
  srcImageSecondaire: any = null
  editImagePrincipale = false
  removeImageSecondaire = false

  exportPlanningAdherentModele: UpdateExportPlanningAdherentModeleModel = {
    removeImageSecondaire: false
  };

  async created() {
    this.loadingExportPlanningAdherentModele = true
    await this.$http.myressif.exportPlanningAdherentModeles
      .getById(this.exportPlanningAdherentModeleId)
      .then((res: ExportPlanningAdherentModele) => {
        this.exportPlanningAdherentModele = res;
        this.srcImagePrincipale = res.images.find(x => !x.isSecondaire)?.fichierUri
        this.srcImageSecondaire = res.images.find(x => x.isSecondaire)?.fichierUri
        this.loadingExportPlanningAdherentModele = false;
      });
  }

  async downloadFile(fournisseurId: string, contratId: string) {
    await this.$http.myressif.exportPlanningAdherentModeles
      .downloadContrat(fournisseurId, contratId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  resetForm() {
    this.exportPlanningAdherentModele = {
      removeImageSecondaire: false
    };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.exportPlanningAdherentModeles
      .update(
        this.exportPlanningAdherentModeleId,
        this.exportPlanningAdherentModele
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du modèle d'export",
            text: "Modèle d'export modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-exportPlanningAdherentModeles");
        this.$emit("update:is-update-export-planning-adherent-modele-sidebar-active", false);
        this.loading = false;
      });
  }

  imagePrincipaleChange(e: any) {
    const file = e.target.files[0];
    this.srcImagePrincipale = URL.createObjectURL(file);
  }

  imageSecondaireChange(e: any) {
    const file = e.target.files[0];
    this.srcImageSecondaire = URL.createObjectURL(file);
  }

  removeImage(isPrincipale: boolean) {
    if (isPrincipale) this.srcImagePrincipale = null
    else {
      this.srcImageSecondaire = null
      this.exportPlanningAdherentModele.removeImageSecondaire = true
    }
  }
}
