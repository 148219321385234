


























































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormFile
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { CreateExportPlanningAdherentModeleModel, ExportPlanningAdherentModeleFichier } from "@/api/models/creneaux/exportPlanningAdherentModele";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormFile
  },
  name: "create-exportPlanningAdherentModele",
})
export default class CreateExportPlanningAdherentModal extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewExportPlanningAdherentModeleSidebarActive!: boolean;
  loading = false;

  required = required;

  imagePrincipaleFichier: any = null
  imageSecondaireFichier: any = null

  srcImagePrincipale: any = null
  srcImageSecondaire: any = null

  newExportPlanningAdherentModele: CreateExportPlanningAdherentModeleModel = {};

  resetForm() {
    this.newExportPlanningAdherentModele = {};
  }


  async submit() {
    this.loading = true;
    await this.$http.myressif.exportPlanningAdherentModeles
      .post(this.newExportPlanningAdherentModele)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un modèle d'export de planning",
            text: "Type de créneau créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-exportPlanningAdherentModeles");
        this.$emit("update:is-add-new-export-planning-adherent-modele-sidebar-active", false);
        this.loading = false;
      });
  }

  imagePrincipaleChange(e: any) {
    const file = e.target.files[0];
    this.srcImagePrincipale = URL.createObjectURL(file);
  }

  imageSecondaireChange(e: any) {
    const file = e.target.files[0];
    this.srcImageSecondaire = URL.createObjectURL(file);
  }
}
